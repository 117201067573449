<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="box-ct">
        <h2 class="tit-h2 d-flex">
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="search()">조회</v-btn>
          </div>
        </h2>
        <div class="table-form mt-3">
          <table>
            <caption class="hide">
              템플릿 항목
            </caption>
            <colgroup>
              <col width="127px"/>
              <col width=""/>
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  템플릿 타입
                </th>
                <td class="px-2">
                  <div class="form-inp sm">
                    <v-select
                      :items="mixin_common_code_get(allCodeList,'TWB151','전체')"
                      item-text="text"
                      item-value="value"
                      outlined
                      hide-details
                      v-model="gridSearch.TMPLAT_TYPE_CD"
                    >
                    </v-select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box-ct mt-3">
        <h2 class="tit-h2 d-flex">
          지식 컨텐츠 템플릿 현황
        </h2>
        <div class="mt-2">
          <v-data-table
            dense
            height="480px"
            :headers="gridDataHeaders"
            :items="gridData"
            item-key="TMPLAT_ID"
            hide-default-footer
            class="grid-default case03"
            single-select
            fixed-header
            :items-per-page="30"
            :page.sync="page"
            @page-count="pageCount = $event"
            :server-items-length="totalCount"
            no-data-text="검색된 결과가 없습니다."
            @update:page="selectRtn"
            @click:row="getListSel"
            v-model="selectedRow"
          >
          <template v-slot:item.PRE_VIEW ="{item}">
            <v-btn v-if="mixin_set_btn($options.name, 'btnPreview')" outlined small class="btn-default" @click="showPreViewDialog(item.TMPLAT_ID)">미리보기</v-btn>
            <!-- <div>{{item.TMPLAT_ID}}</div> -->
          </template>
          <template v-slot:item.CHOOSE ="{item}">
            <v-btn v-if="mixin_set_btn($options.name, 'btnChoice')" outlined small class="btn-default" @click="processRtnChoose(item.TMPLAT_ID)">선택</v-btn>
          </template>
          </v-data-table>
          <v-dialog max-width="1400" v-model="dialogM510301P03" v-if="dialogM510301P03"
            content-class="square-modal modal-white">
            <dialog-M510301P01
              headerTitle="지식컨텐츠 템플릿 미리보기"
              :params = "detailParams"
              p03
              @hide="hideDialog('M510301P03')"
            >
            </dialog-M510301P01>
          </v-dialog>
        </div>

        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
          <p class="caption text-left">
            총 <strong class="clr-error">{{ totalCount }}</strong
            >건
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
import DialogM510301P01 from "./M510301P01.vue"

export default {
  name: "MENU_M510201P07", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    ASP_NEWCUST_KEY : {
      type : String,
      default: "",
    },
  },
  components:{
    DialogM510301P01,
  },
  mixins : [mixin],
  data: () => ({
    selectedRow : [],
    dialogM510301P03 : false,
    gridDataHeaders: [
      { text:"템플릿타입" , value:"TMPLAT_TYPE_NM", align:"left", sortable: true,},
      { text:"템플릿명" , value:"TITL", align:"left", sortable: true,},
      { text:"미리보기" , value:"PRE_VIEW", align:"center", sortable: true,},
      { text:"선택" , value:"CHOOSE", align:"center", sortable: true,},
    ],
    gridData: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    totalCount: 0,
    gridSearch: {
      TMPLAT_TYPE_CD : ""
    },
    groupCdList : ['TWB151'],
    allCodeList : [],
    detailParams :{},
  }),
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  },
  created(){
   this.search();
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    search(){
      this.page = 1;
      this.selectRtn();
    },
    async selectRtn(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/template/manage/page/list-pop";
      requestData.headers["SERVICE"] = "km.template.manage.page";
      requestData.headers["METHOD"] = "list-pop";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;
      requestData.headers["ROW_CNT"] = this.itemsPerPage;
      requestData.headers["PAGES_CNT"] = this.page;

      // sendData 세팅
      requestData.sendData = this.gridSearch;
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY

      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        this.gridData = response.DATA;
        this.totalCount = parseInt(response.DATA[0].TWB_PAGING_TOT_COUNT);
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    async showPreViewDialog(TMPLAT_ID){
      this.detailParams = {};
      await this.getTmplt(TMPLAT_ID);
      if(this.detailParams.id != null && this.detailParams.id != ""){
        this.showDialog('M510301P03');
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    async processRtnChoose(TMPLAT_ID){
      this.detailParams = {};
      await this.getTmplt(TMPLAT_ID);
      if(this.detailParams.id != null && this.detailParams.id != ""){
        this.$emit('submit', this.detailParams);
      }else{
        this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
      }
    },
    async getTmplt(TMPLAT_ID){
      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/km/template/detail/inqire";
      requestData.headers["SERVICE"] = 'km.template.detail';
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = false;

      requestData.sendData["TMPLAT_ID"] = TMPLAT_ID;       //템플릿ID
      requestData.sendData["ASP_NEWCUST_KEY"] =this.ASP_NEWCUST_KEY;  //회사구분


      let response = await this.common_postCall(requestData);
      if(response.DATA[0].TMPLAT_ID != null && response.DATA[0].TMPLAT_ID != ""){
        this.detailParams = {
          id : response.DATA[0].TMPLAT_ID,
          contn : this.restoreXSS_CKeditor(response.DATA[0].CONTN),
        }
      }
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
  },

  computed: {

  },
};
</script>

<style></style>
