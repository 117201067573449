<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon small class="svg-close20" @click="$emit('hide')">mdi-close</v-icon>
      </div>
    </h1>
    <div class="modal-box">
       <div class="mt-2">
        <v-data-table
          dense
          :headers="gridDataHeaders"
          :items="gridDataText"
          item-key="FILE_KEY"
          hide-default-footer
          class="grid-default"
        >
        <template v-slot:item.FILE_SIZE = {item}>
            {{mixin_getFileSize(item.FILE_SIZE)}}
        </template>
        <template v-slot:item.DOWNLOAD = {item}>
          <div>
            <v-btn class="btn-default" @click="mixin_fileDownload(item, busiType, pathType)">다운</v-btn>
          </div>
        </template>
        </v-data-table>
      </div>

      <div class="grid-paging text-center pt-2">
        <p class="caption text-left">
          총 <strong class="clr-error">{{ gridDataText.length }}</strong
          >건
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "FileList", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    FILE_GROUP_KEY : "",
    ASP_NEWCUST_KEY : "",
    busiType : "",
    pathType : "",
  },
  mixins : [mixin],
  data: () => ({
    gridDataHeaders: [
        { text : "파일명", value: "ORIGINAL_FILENAME", align:"center", sortable:false,},
        { text : "파일사이즈", value: "FILE_SIZE", align:"center", sortable:false, width: "100px",},
        { text : "다운로드", value: "DOWNLOAD", align:"center", sortable:false, width: "100px",},
        { text : "다운로드수", value: "DNLOD_CNT", align:"center", sortable:false, width: "100px",},
    ],
    gridDataText: [],
  }),
  async created(){
    this.gridDataText = await this.mixin_getFileList(this.FILE_GROUP_KEY);
    console.log(this.busiType);
    console.log(this.pathType);
  },
  methods: {

  },

  computed: {

  },
};
</script>

<style></style>
