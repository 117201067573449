<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="box-ct">
        <h2 class="tit-h2 d-flex">
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnComList')" outlined class="btn-point" @click="$emit('recommendDialog')">추천목록</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnModyHst')" outlined class="btn-point ml-2" @click="$emit('modifyDialog')">수정이력</v-btn>
          </div>
        </h2>
      </div>
      <div class="table-form mt-3">
        <table>
          <caption class="hide">
            사용자정보 항목
          </caption>
          <colgroup>
            <col width="127px"/>
            <col width="291px"/>
            <col width="127px"/>
            <col width="291px"/>
            <col width="127px"/>
            <col width="291px"/>
            <col width="127px"/>
            <col width=""/>
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                최초등록자
              </th>
              <td class="px-2">
                {{DETAIL.REGR_NM}}
              </td>
              <th scope="row">
                최초등록일시
              </th>
              <td class="px-2">
                {{DETAIL.REG_DTTM}}
              </td>
              <th scope="row">
                컨텐츠번호
              </th>
              <td class="px-2">
                {{DETAIL.CNTNT_NO}}
              </td>
              <th scope="row">
                버전
              </th>
              <td class="px-2">
                {{DETAIL.VER}}
              </td>
            </tr>
            <tr>
              <th scope="row">
                최근수정자
              </th>
              <td class="px-2">
                {{DETAIL.AMDR_NM}}
              </td>
              <th scope="row">
                최근수정일시
              </th>
              <td class="px-2">
                {{DETAIL.UPD_DTTM}}
              </td>
              <th scope="row">
                색인어
              </th>
              <td colspan="3" class="px-2">
                {{DETAIL.SRCH_KWD}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-ct mt-3 d-flex">
        <div class="col-6 pa-0 pr-2">
          <v-data-table
            dense
            :headers="grid01Headers"
            :items="grid01Data"
            item-key="index"
            hide-default-footer
            class="grid-default mt-2 case03"
            single-select
            fixed-header
            calculate-widths
            @click:row="getListSel"
            v-model="selectedRow"
          >
          <template v-slot:item.FILE_SIZE = {item}>
            {{mixin_getFileSize(item.FILE_SIZE)}}
          </template>
          <template v-slot:item.DOWNLOAD = {item}>
            <div>
              <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="small btn-default" @click="mixin_fileDownload(item, 'km', 'files')">다운</v-btn>
            </div>
          </template>
          </v-data-table>

        </div>
        <div class="col-6 pa-0 pl-2">
          <v-data-table
            dense
            :headers="grid02Headers"
            :items="grid02Data"
            item-key="index"
            hide-default-footer
            class="grid-default mt-2 case03"
            fixed-header
            single-select
            calculate-widths
            @click:row="getListSel"
            v-model="selectedRow2"
          >
          </v-data-table>
        </div>
      </div>
      <div class="box-ct mt-3">
        <div class="mt-2">
          <ckeditor :value="DETAIL.CONTN" :config="editorConfig" :read-only="true"></ckeditor>
        </div>
      </div>
      <div class="mt-3">
        <div class="d-flex align-center">
          <div class="col-3 pa-0">
            <div class="gray-box">
              <div>
                <span class="in-label">추천점수</span>
                <span class="in-value">{{RCMD.SUM_RCMD_SCOR}}</span>
              </div>
              <div class="mt-1">
                <span class="in-label">추천인원</span>
                <span class="in-value">{{RCMD.CNT_USER_ID}}</span>
              </div>
            </div>
          </div>
          <div class="flex-grow-1 pl-2">
            <div>
              <v-radio-group v-model="RCMD.RCMD_SCOR" row class="radio-inline" >
                <v-radio v-for="(rad01,index) in radioGroup" :key="radioGroup[index].text"
                  :on-icon="'svg-rad-on'"
                  :off-icon="'svg-rad-off'"
                  :value="rad01.value"
                  >
                  <template v-slot:label>
                    <span class="clr-error">{{ rad01.icon }}</span>
                    <span class="ml-1">{{ rad01.text }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex align-center mt-2">
              <v-text-field
                  class="form-inp full pt-0"
                  name="name" label="" id="id" outlined
                  hide-details
                  placeholder="추천사유를 입력하세요"
                  v-model="RCMD.RCMD_RSN"
                  >
              </v-text-field>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="common_confirm('추천 하시겠습니까?',processRtnRecommend, null, '', null, 'chk')">저장</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M510201P03", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    CNTNT_ID : {
      type : String,
      default: "",
    },
    ASP_NEWCUST_KEY :{
      type : String,
      default: "",
    },
  },
  mixins: [mixin],
  data: () => ({
    selectedRow:[],
    selectedRow2:[],

    radioGroup: [{
        icon: '★★★★★',
        text: '매우만족',
        value : "10",
      },
      {
        icon: '★★★★',
        text: '만족',
        value : "7",
      },
      {
        icon: '★★★',
        text: '보통',
        value : "5",
      },
      {
        icon: '★★',
        text: '미흡',
        value : "3",
      }
    ],

    grid01Headers: [
      { text : "파일명", value: "ORIGINAL_FILENAME", align:"left", sortable:true,},
      { text : "파일사이즈", value: "FILE_SIZE", align:"center", sortable:true,},
      { text : "다운로드", value: "DOWNLOAD", align:"center", sortable:true,},
      { text : "다운로드수", value: "DNLOD_CNT", align:"center", sortable:true,},
    ],
    grid01Data: [],
    grid02Headers : [
      { text: "번호", value: "CNTNT_NO", align: "center", sortable:true },
      { text: "연결컨텐츠 제목", value: "TITL", align: "left", sortable:true },
      { text: "컨텐츠분류", value: "FULL_DIV_NM", align: "left", sortable:true },
    ],
    grid02Data: [],
    DETAIL:{
      FULL_DIV_NM : "",
      REGR_NM : "",
      REG_DTTM : "",
      TITL : "",
      AMDR_NM : "",
      UPD_DTTM : "",
      CNTNT_NO : "",
      VER : "",
      CNTNT_ID : "",
      CONTN : "",
      SRCH_KWD : "",
      FILE_GROUP_KEY : "",
    },
    RCMD : {
      SUM_RCMD_SCOR : 0,
      CNT_USER_ID : 0,
      RCMD_RSN : "",
      RCMD_SCOR : 0,
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    editorConfig: {
      toolbarGroups: [
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'about', groups: [ 'about' ] }
      ],
      removeButtons: "About",
      height: "300px",
    },
  }),
  async created(){
    await this.selectRtn();
    await this.selectRtnRecomment();
    this.grid01Data = await this.mixin_getFileList(this.DETAIL.FILE_GROUP_KEY);

    let idx = 1;


     for(let i in this.grid01Data){
       this.grid01Data[i]["index"] = idx++;
     }
    console.log(this.grid01Data);
  },
  methods: {
    //Table row 클릭이벤트
    getListSel(item, row) {
      if(item != null){
        this.activeRow(row,true);
      }
    },

    activeRow(row, value){
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    async selectRtn(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/detail/inqire";
      requestData.headers["SERVICE"] = "km.content.detail";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['CNTNT_ID'] = this.CNTNT_ID;
      requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData['DEPT_CD'] = "x";
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;



      // 결과 리턴
      let response = await this.common_postCall(requestData, false);
      let idx = 0;

      if(!response.HEADER.ERROR_FLAG){
        //this.grid01Data = response.FILE_GROUP_KEY
        this.grid02Data = response.DATA_CONNECT;
        for(let i in this.grid02Data){
          this.grid02Data[i]["index"] = idx++;
        }
        this.DETAIL = response.DATA[0];
        this.DETAIL.CONTN = this.restoreXSS_CKeditor(response.DATA[0].CONTN);
      }
    },
    async selectRtnRecomment(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/detail/rcmd/inqire";
      requestData.headers["SERVICE"] = "km.content.detail.rcmd";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['CNTNT_ID'] = this.CNTNT_ID;

      // 결과 리턴
      let response = await this.common_postCall(requestData);
      if(!response.HEADER.ERROR_FLAG){
        if(response.DATA.length > 0){
          this.RCMD.SUM_RCMD_SCOR = response.DATA[0].SUM_RCMD_SCOR
          this.RCMD.CNT_USER_ID = response.DATA[0].CNT_USER_ID
        }
      }
    },

    async processRtnRecommend(){
      if(this.RCMD.RCMD_SCOR == 0){
        this.common_alert("추천점수를 선택해주세요.", "error");
        return;
      }

      if(this.RCMD.RCMD_RSN == ""){
        this.common_alert("추천사유를 입력하세요.", "error");
        return;
      }

      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/detail/rcmd/process";
      requestData.headers["SERVICE"] = "km.content.detail.rcmd";
      requestData.headers["METHOD"] = "process";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['CNTNT_ID'] = this.CNTNT_ID;
      requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData['RCMD_SCOR'] = this.RCMD.RCMD_SCOR;
      requestData.sendData['RCMD_RSN'] = this.RCMD.RCMD_RSN;
      requestData.sendData['WRTR_DEPT_CD'] = "x";
      requestData.sendData['WRTR_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData['PROC_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;
      requestData.sendData['DATA_FLAG'] = "I";



      // 결과 리턴
      let response = await this.common_postCall(requestData, false);

      if(!response.HEADER.ERROR_FLAG){
        this.common_alert(response.HEADER.ERROR_MSG, "done");
        this.RCMD.RCMD_RSN = "";
        this.RCMD.RCMD_SCOR = 0;
        this.selectRtnRecomment();
      }else{
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },


  },

  computed: {

  },
};
</script>

<style scoped>
.gray-box {
  padding: 6px;
  background: #F1F1F4;
  border-radius: 3px;
}
.gray-box > div {
  display: flex;
  align-items: center;
}
.gray-box .in-label {
    width: 120px;
    display: inline-block;
    text-align: center;
}
.gray-box .in-value {
  flex-grow: 1;
  margin-left: auto;
  font-size: 12px;
  text-align: right;
  background: white;
  padding: 5px;
  border-radius: 6px;
}
</style>
