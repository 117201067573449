<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>

    </h1>
    <div class="modal-box" style="overflow-y:auto">
      <div class="tree-wrap">
        <template>
          <v-treeview
            :items="treeItems"
            activatable
            open-on-click
            return-object
            @update:active="selectTreeview"
          >
          <template v-slot:prepend="{ open }">
            <v-icon color="#8B98DD">
              {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
          </template>
          </v-treeview>
        </template>
      </div>
      <div class="d-flex">
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnMove')" outlined class="btn-default ml-2" @click="updateRtnMove()">이동</v-btn>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mixin} from '@/mixin/mixin.js'
export default {
  name: "MENU_M510201P01", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    gridChecked : [],
    ASP_NEWCUST_KEY : "",
  },
  mixins : [mixin],
  data: () => ({
    treeItems: [],
    selectedNode: {},
    test: {},
  }),
  created(){
    this.selectRtnTree();
  },
  methods: {
    async selectRtnTree(){
      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/category/manage/tr/inqire";
      requestData.headers["SERVICE"] = "km.category.manage.tr";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;
      // 결과 리턴
      let response = await this.common_postCall(requestData);

      if(!response.HEADER.ERROR_FLAG){
        let items = [];
        let data = response.DATA;
        if(data.length !=0 ){
          for(var i=0; i<data.length; i++){
            let element = {
              cNode: data[i].C_NODE_NO,       //분류ID
              pNode: data[i].P_NODE_NO,       //상위분류ID
              id: data[i].NODE_ID,            //item-key
              name: data[i].NODE_TITLE,       //item-text
              nodeLv: data[i].NODE_LVL ,      //분류 레벨 (start 0)
              nodeSort: data[i].NODE_SORT,    //정렬순서
              nodeType: data[i].NODE_TYPE,    //default 'D'
            }

            element.children = [];

            if(element.nodeLv == 2){
              for(var j=0; j<items.length; j++){
                if(element.pNode == items[j].cNode) {
                  items[j].children.push(element);
                }
              }
            } else if (element.nodeLv == 3){
              //마지막 lv
              for(var k=0; k<items.length; k++){
                for(var l=0; l<items[k].children.length; l++){
                  if(element.pNode == items[k].children[l].cNode){
                    items[k].children[l].children.push(element);
                  }
                }
              }
            } else {
              items.push(element);
            }
          }
        } else {
          this.common_alert("조회된 데이터가 없습니다", 'error');
        }
        this.treeItems = items;
      }
    },
    selectTreeview(item){
      this.selectedNode = item[0];
    },
    async updateRtnMove(){

      if(this.selectedNode.cNode != null && this.selectedNode.cNode != ""){
        if(this.selectedNode.nodeLv != "3"){
          this.common_alert("해당 레벨분류로는 이동할 수 없습니다.", 'error');
        }else{
          let requestData = {
            headers: {},
            sendData:{}
          };
          // header 세팅
          requestData.headers["URL"] = "api/km/category/mvmn/process";
          requestData.headers["SERVICE"] = "km.category.mvmn";
          requestData.headers["METHOD"] = "process";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["ASYNC"] = false;
          requestData.headers["DATA_OBJECT"] = "CONT_LIST";
          // sendData 세팅
          requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
          requestData.sendData["CONT_LIST"] = this.gridChecked;
          requestData.sendData["DIV_ID"] = this.selectedNode.cNode;
          requestData.sendData["AMDR_DEPT_CD"] = "x";
          requestData.sendData["AMDR_ID"] = this.$store.getters['userStore/GE_USER_ID'];
          requestData.sendData["USER_ID"] = this.$store.getters['userStore/GE_USER_ID'];
          requestData.sendData["PROC_ID"] = this.$store.getters['userStore/GE_USER_ID'];


          // 결과 리턴
          let response = await this.common_postCall(requestData);

          if(!response.HEADER.ERROR_FLAG){
            this.common_alert("정상처리되었습니다.", "done");
            this.$emit('submit');
          }
        }
      }else{
        this.common_alert("이동할 분류가 선택되지 않았습니다.", 'error');
      }
    },
  },

  computed: {

  },
};
</script>

<style></style>
