<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon small class="svg-close20" @click="$emit('hide')">mdi-close</v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div class="table-form">
        <table>
          <caption class="hide">
            사용자정보 항목
          </caption>
          <colgroup>
            <col width="127px"/>
            <col width=""/>
            <col width=""/>
            <col width=""/>
            <col width="127px"/>
            <col width=""/>
            <col width="127px"/>
            <col width=""/>
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                회사구분
              </th>
              <td colspan="7" class="px-2">
                <div class="form-inp full">
                  <v-select
                    :items="$store.getters['userStore/GE_USER_COMPANY']"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    disabled
                    v-model="ASP_NEWCUST_KEY"
                    :readonly="RDOnly"
                  >
                  </v-select>
                </div>
              </td>

            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">컨텐츠분류</v-badge>
              </th>
              <td colspan="3" class="px-2">
                <div class="form-inp sm">
                  <v-select
                    :items="DIV_ID_1LEVEL_ITEMS"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.DIV_ID_1LEVEL"
                    @change="changeDivId('1')"
                  >
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="DIV_ID_2LEVEL_ITEMS"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.DIV_ID_2LEVEL"
                    @change="changeDivId('2')"
                  >
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="DIV_ID_3LEVEL_ITEMS"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.DIV_ID_3LEVEL"
                  >
                  </v-select>
                </div>
              </td>
              <th scope="row">
                <template v-if="params.STATE == 'MODIFY'">
                  수정자소속
                </template>
                <template v-else>
                  작성자소속
                </template>
              </th>
              <td colspan="3" class="px-2">
                <div class="form-inp sm">
                  <v-select
                    :items="USER_ATTR_A_ITEMS"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.USER_ATTR_A"
                  >
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="USER_ATTR_B_ITEMS"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.USER_ATTR_B"
                  >
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="USER_ATTR_C_ITEMS"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.USER_ATTR_C"
                  >
                  </v-select>
                </div>
                <div class="form-inp sm ml-2">
                  <v-select
                    :items="USER_ATTR_D_ITEMS"
                    item-text="CD_NM"
                    item-value="CD"
                    outlined
                    hide-details
                    placeholder="선택하세요"
                    v-model="registParams.USER_ATTR_D"
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">제목</v-badge>
              </th>
              <td colspan="3" class="px-2">
                <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  v-model="registParams.TITL"
                  >
                </v-text-field>
              </td>
              <th scope="row">
                <template v-if="params.STATE == 'MODIFY'">
                  수정자
                </template>
                <template v-else>
                  작성자
                </template>
              </th>
              <td colspan="" class="px-2">

                <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  :value="this.$store.getters['userStore/GE_USER_NAME']"
                  readonly
                  >
                </v-text-field>
              </td>
              <th scope="row">
                색인어
              </th>
              <td colspan="" class="px-2">
                <v-text-field
                  class="form-inp full"
                  name="name" label="" id="id" outlined
                  hide-details
                  v-model="registParams.SRCH_KWD"
                  >
                </v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="box-ct mt-3 d-flex">
        <div class="col-6 pa-0 pr-2">
         <h2 class="tit-h2 d-flex">
          첨부파일 현황
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd1')" outlined class="btn-point" @click="showDialog('VueDropzone')">추가</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" outlined class="btn-etc ml-2" @click="deleteRtnFile()">삭제</v-btn>
              <v-dialog max-width="800" persistent
                v-model="dialogVueDropzone" v-if="dialogVueDropzone" content-class="square-modal"
                >
                <dialog-VueDropzone
                  headerTitle="첨부파일 등록"
                  :ASP_NEWCUST_KEY="ASP_NEWCUST_KEY"
                  :fileGroupKey="registParams.FILE_GROUP_KEY"
                  busiType="km"
                  pathType="files"
                  acceptedFiles=".png,.jpg,.gif,.jpeg,.doc,.docx,.pdf,.csv,.xls,.xlsx,.ppt"
                  @hide="hideDialog('VueDropzone')"
                  @submit="uploadFile"
                >
                </dialog-VueDropzone>
              </v-dialog>
            </div>
          </h2>
          <v-data-table
            dense
            :headers="grid01Header"
            :items="grid01Data"
            item-key="FILE_KEY"
            hide-default-footer
            class="grid-default case03 mt-2"
            calculate-widths
            fixed-header
            no-data-text="등록된 데이터가 없습니다."
            @click:row="setGird01Selected"
            :item-class="isActiveRow"
          >
          <template v-slot:item.FILE_SIZE = {item}>
            {{mixin_getFileSize(item.FILE_SIZE)}}
          </template>
          <template v-slot:item.DOWNLOAD = {item}>
            <div>
              <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="btn-default" @click="mixin_fileDownload(item, 'km', 'files')">다운</v-btn>
            </div>
          </template>
          </v-data-table>

        </div>
        <div class="col-6 pa-0 pl-2">
         <h2 class="tit-h2 d-flex">
          연결컨텐츠 현황
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd2')" outlined class="btn-point" @click="showDialog('M510201P06')">추가</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" outlined class="btn-etc ml-2" @click="deleteRtnCnnc()">삭제</v-btn>
              <v-dialog max-width="800" persistent
                v-model="dialogM510201P06" v-if="dialogM510201P06" content-class="square-modal"
                >
                <dialog-M510201P06
                  headerTitle="컨텐츠 연결"
                  :ASP_NEWCUST_KEY="ASP_NEWCUST_KEY"
                  :gridData="grid02Data"
                  @hide="hideDialog('M510201P06')"
                  @submit="getCnnc"
                >
                </dialog-M510201P06>
              </v-dialog>
            </div>
          </h2>
          <v-data-table
            dense
            :headers="grid02Header"
            :items="grid02Data"
            item-key="CNTNT_NO"
            hide-default-footer
            class="grid-default case03 mt-2"
            fixed-header
            calculate-widths
            no-data-text="등록된 데이터가 없습니다."
            @click:row="setGird02Selected"
            :item-class="isActiveRow2"
          >
          </v-data-table>
        </div>

      </div>
      <div class="box-ct mt-3">
        <h2 class="tit-h2 d-flex">
          컨텐츠 내용
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnFindTmp')" outlined class="btn-default" @click="showDialog('M510201P07')">템플릿 찾기</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnApplyTmp')" outlined class="btn-point ml-2" @click="findRtn('APPLY')">템플릿 적용하기</v-btn>
            <v-dialog max-width="655" persistent
              v-model="dialogM510201P07" v-if="dialogM510201P07" content-class="square-modal"
              >
              <dialog-M510201P07
                headerTitle="템플릿 찾기"
                :ASP_NEWCUST_KEY="ASP_NEWCUST_KEY"
                @hide="hideDialog('M510201P07')"
                @submit="getTmplt"
              >
              </dialog-M510201P07>
            </v-dialog>
          </div>
        </h2>
        <div class="mt-2">
          <ckeditor v-model="registParams.CONTN" :config="editorConfig"></ckeditor>
        </div>
      </div>
      <div class="box-ct mt-3">
        <div class="text-right">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSaveMin')" outlined class="btn-point" v-show="btnTemp" @click="showTempSave('TEMP')">임시저장</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete3')" outlined class="btn-etc ml-2" v-show="btnDelete">삭제</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave3')" outlined class="btn-point ml-2" v-show="btnSave" @click="showTempSave('SAVE')">저장</v-btn>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAccess')" outlined class="btn-point ml-2" v-show="btnApprove" @click="showTempSave('APPROVAL')">승인</v-btn>
        </div>
        <v-dialog max-width="655" persistent
          v-model="dialogM510201P08" v-if="dialogM510201P08" content-class="square-modal"
          >
          <dialog-M510201P08
            headerTitle="지식컨텐츠변경이력관리"
            :ASP_NEWCUST_KEY="ASP_NEWCUST_KEY"
            :STATE="params.STATE"
            :PROC_CASE="mstrCase"
            :CNTNT_ID="mstrCntntID"
            :CNTNT_SEQ="mstrCntntSeq"
            :KEY_VER_SEQ="mstrKeyVerSeq"
            @hide="hideDialog('M510201P08')"
            @submit="processRtn"
          >
          </dialog-M510201P08>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js';
import DialogM510201P06 from "./M510201P06.vue";
import DialogM510201P07 from "./M510201P07.vue";
import DialogM510201P08 from "./M510201P08.vue";
import DialogVueDropzone from "@/components/VueDropzone.vue";
//import fileDropzone from "@/components/VueDropzone.vue"
export default {
  name: "MENU_M510201P02", //name은 'MENU_' + 파일명 조합
  props: {
    headerTitle: {
      type: String,
      default: "제목",
    },
    ASP_NEWCUST_KEY : "",
    params : {
      STATE : "",
      PROC_CASE : "",
      CNTNT_ID : "",
      CNTNT_SEQ : "",
      KEY_VER_SEQ : "",
      CNTNT_KEY : "",
      SAVE_STAT_CD : "",
    },
  },
  components: {
    DialogM510201P06,
    DialogM510201P07,
    DialogM510201P08,
    DialogVueDropzone,
  },
  mixins : [mixin],
  data: () => ({
    dialogM510201P06: false,
    dialogM510201P07: false,
    dialogM510201P08: false,
    dialogVueDropzone: false,

    editorConfig: {
      toolbarGroups: [
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'about', groups: [ 'about' ] }
      ],
      removeButtons: "About",
      height: "300px",
    },
    DIV_ID_1LEVEL_ITEMS:[],
    DIV_ID_2LEVEL_ITEMS:[],
    DIV_ID_3LEVEL_ITEMS:[],
    USER_ATTR_A_ITEMS : [],
    USER_ATTR_B_ITEMS : [],
    USER_ATTR_C_ITEMS : [],
    USER_ATTR_D_ITEMS : [],
    dropItems: [{ title: "option1" }],
    registParams : {
      DIV_ID_1LEVEL : "",
      DIV_ID_2LEVEL : "",
      DIV_ID_3LEVEL : "",
      USER_ATTR_A : "",
      USER_ATTR_B : "",
      USER_ATTR_C : "",
      USER_ATTR_D : "",
      TITL: "",
      WRD_SIZE : "",
      WRD_COLOR : "",
      REGR_ID : "",
      REGR_NM : "",
      AMDR_ID : "",
      AMDR_NM : "",
      SRCH_KWD : "",
      TMPLAT_ID : "",
      CONTN : "",
      FILE_GROUP_KEY : "",
      FST_STRT_DT : "",
    },
    grid01Header:[
      { text : "파일명", value: "ORIGINAL_FILENAME", align:"left", sortable:true,},
      { text : "파일사이즈", value: "FILE_SIZE", align:"center", sortable:true,},
      { text : "다운로드", value: "DOWNLOAD", align:"center", sortable:true,},
      { text : "다운로드수", value: "DNLOD_CNT", align:"center", sortable:true,},
    ],
    grid01Data:[],
    grid01Selected:{},
    grid02Header:[
      { text : "컨텐츠번호", value: "CNTNT_NO", align:"center", sortable:true,},
      { text : "컨텐츠분류", value: "FULL_DIV_NM", align:"left", sortable:true,},
      { text : "제목", value: "TITL", align:"left", sortable:true,},
      { text : "컨텐츠ID", value: "CONNECT_CNTNT_ID", align:"left", sortable:true,},
    ],
    grid02Data:[],
    grid02Selected : {},
    gridDataHeaders: [
        { text: "No", value: "index", align: "center", sortable:true },
        {
          text: "사용자 그룹",
          value: "index01",
          align: "center",
          sortable:  true,
        },
        { text: "사용자명", value: "index02", align: "center", sortable:true },
        {
          text: "연차",
          value: "index03",
          align: "center",
          sortable: true,
        },
        {
          text: "반차",
          value: "index04",
          align: "center",
          sortable: true,
        },

      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,
      mstrSystemID: "KMC",
      mstrKeyVerSeq : "",
      mstrState: "",
      mstrCntntID: "",
      mstrCntntKey: "",
      mstrDiv1Level: "",
      mstrDiv2Level: "",
      mstrDiv3Level: "",
      mstrSaveStatCD: "",
      mstrCntntVer: "",
      mstrCntntSeq: "",
      mstrSaveHistYN: "",
      mstrCase: "",
      mstrChgHistContn: "",
      mstrHtmlData: "",

      btnSave: false,
      btnTemp: true,
      btnDelete: false,
      btnApprove: false,

      RDOnly:false,
  }),
  async mounted(){

    this.mstrKeyVerSeq = await this.selectBizKeyVerSeq(this.mstrSystemID);

    if(this.params.CNTNT_KEY == ""){
      this.mstrCntntKey = this.mstrKeyVerSeq;
      this.mstrCntntID = this.mstrCntntKey.substring(0, this.mstrCntntKey.indexOf("_"));
    }else{
      this.mstrCntntKey = this.params.CNTNT_KEY;
      this.mstrCntntID = this.params.CNTNT_ID;
    }

    this.mstrCntntVer = this.mstrCntntKey.substring(this.mstrCntntKey.indexOf("_")+2, this.mstrCntntKey.lastIndexOf("_"));
		this.mstrCntntVer = parseInt(this.mstrCntntVer.substr(0,2)).toString() + "." + parseInt(this.mstrCntntVer.substr(2,4)).toString();
		this.mstrCntntSeq = this.mstrCntntKey.substring(this.mstrCntntKey.lastIndexOf("_")+1, this.mstrCntntKey.length);

    //  console.log("mstrKeyVerSeq :" + this.mstrKeyVerSeq);
    //  console.log("mstrCntntKey :" + this.mstrCntntKey);
    //  console.log("mstrCntntID :" + this.mstrCntntID);
    //  console.log("mstrCntntVer :" + this.mstrCntntVer);
    //  console.log("mstrCntntSeq :" + this.mstrCntntSeq);

    if(this.params.STATE == 'NEW'){
      //신규등록
      this.registParams.REGR_ID = this.$store.getters['userStore/GE_USER_ID'];
      this.registParams.REGR_NM = this.$store.getters['userStore/GE_USER_NAME'];
      this.registParams.DIV_ID_1LEVEL = this.params.DIV_ID_1LEVEL;
      if(this.registParams.DIV_ID_1LEVEL != ""){
        let DIV_ID_2LEVEL = await this.mixin_getDivCodeData(this.registParams.DIV_ID_1LEVEL);
        this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_2LEVEL,this.registParams.DIV_ID_1LEVEL,"선택");
      }

      this.registParams.DIV_ID_2LEVEL = this.params.DIV_ID_2LEVEL;
      if(this.registParams.DIV_ID_2LEVEL != ""){
        let DIV_ID_3LEVEL = await this.mixin_getDivCodeData(this.registParams.DIV_ID_2LEVEL);
        this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_3LEVEL,this.registParams.DIV_ID_2LEVEL,"선택");
      }
      this.registParams.DIV_ID_3LEVEL = this.params.DIV_ID_3LEVEL;
    }

    if(this.params.STATE == "MODIFY"){
      await this.selectRtn();
      this.grid01Data = await this.mixin_getFileList(this.registParams.FILE_GROUP_KEY);

    }


    this.registParams.AMDR_ID = this.$store.getters['userStore/GE_USER_ID'];
    this.registParams.AMDR_NM = this.$store.getters['userStore/GE_USER_NAME'];

    await this.selectRtnUserAttr();

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  async created(){
    let DIV_ID_1LEVEL = await this.mixin_getDivCodeData('0');
    this.DIV_ID_1LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_1LEVEL,'0','선택');
    this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get("","","선택");
    this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","선택");

    this.USER_ATTR_A_ITEMS = await this.mixin_attr_get(this.ASP_NEWCUST_KEY, "A", "선택");
    this.USER_ATTR_B_ITEMS = await this.mixin_attr_get(this.ASP_NEWCUST_KEY, "B", "선택");
    this.USER_ATTR_C_ITEMS = await this.mixin_attr_get(this.ASP_NEWCUST_KEY, "C", "선택");
    this.USER_ATTR_D_ITEMS = await this.mixin_attr_get(this.ASP_NEWCUST_KEY, "D", "선택");

    if(this.params.SAVE_STAT_CD == "01"){		// 임시저장일 경우에는 승인버튼으로 활성화
      this.btnSave = false;
      this.btnApprove = true;
		}else if(this.params.SAVE_STAT_CD == ""){	// 신규저장일 경우에는 승인버튼, 저장버튼 비활성화
      this.btnApprove = false;
      this.btnSave = false;
      this.btnDelete = false;
		}else{							// 그 외의 경우 저장버튼으로 활성화
      this.btnApprove = false;
      this.btnSave = true;
		}


  },
  methods: {
    async findRtn(strCase){
      // 템플릿 적용하기
      if(strCase === "INIT_APPLY" || strCase === "APPLY"){
        if(this.registParams.DIV_ID_1LEVEL == ""){
          this.common_alert('선택된 분류가 없습니다.<br/>템플릿을 적용할 분류를 선택하세요', 'error');
          return;
        }else{
          let requestData = {
            headers: {},
            sendData:{}
          };
          // header 세팅
          requestData.headers["URL"] = "api/km/content/regist/cl-tmplat/inqire";
          requestData.headers["SERVICE"] = "km.content.regist.cl-tmplat";
          requestData.headers["METHOD"] = "inqire";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["ASYNC"] = false;

          // sendData 세팅
          requestData.sendData['DIV_ID_1LEVEL'] = this.registParams.DIV_ID_1LEVEL;
          requestData.sendData['DIV_ID_2LEVEL'] = this.registParams.DIV_ID_2LEVEL;
          requestData.sendData['DIV_ID_3LEVEL'] = this.registParams.DIV_ID_3LEVEL;

          // 결과 리턴
          let response = await this.common_postCall(requestData, false);

          if(!response.HEADER.ERROR_FLAG){
            this.registParams.TMPLAT_ID = response.DATA[0].TMPLAT_ID;
          }

          //적용하기를 클릭할 경우 템플릿 ID가 없을 경우 알림 메시지
          if(strCase === "APPLY" && this.registParams.TMPLAT_ID == ""){
            this.common_alert('적용할 템플릿이 없습니다.', 'error');
            return;
          }
        }
      }

      if(this.registParams.TMPLAT_ID != "") {

        let requestData = {
          headers: {},
          sendData:{}
        };

        // header 세팅
        requestData.headers["URL"] = "api/km/content/regist/tmplat/inqire";
        requestData.headers["SERVICE"] = "km.content.regist.tmplat";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['TMPLAT_ID'] = this.registParams.TMPLAT_ID;

        // 결과 리턴
        let response = await this.common_postCall(requestData, false);

        //웹에디터에 아무 입력이 없는 부분 체크
        if(this.registParams.CONTN != "") {
          this.common_confirm("템플릿을 적용하면 웹에디터의 작성된 모든내용이 사라집니다. 템플릿을 적용 하시겠습니까?", this.setTmplt2, response.DATA[0],null,'','chk')
        }else{
          let response = await this.common_postCall(requestData, false);
          this.registParams.CONTN = response.DATA[0].CONTN;
        }

      }else{
        this.common_alert('적용할 템플릿이 없습니다.', 'error');
        return;
      }
    },

    async selectBizKeyVerSeq(strBizCase) {
      var strKeyVerSeq = await this.mixin_common_bizSeq_get(strBizCase);
      if(!strKeyVerSeq !== ""){
        return strKeyVerSeq + "_v0100_00001";
      } else {
        return "";
      }
    },

    async selectRtn(){
      if(this.params.STATE != ""){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/km/content/regist/inqire";
        requestData.headers["SERVICE"] = "km.content.regist";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['CNTNT_ID'] = this.mstrCntntID;

        if(this.params.STATE == "HIST"){
          requestData.headers["URL"] = "api/km/content/regist/detail-hist/inqire";
          requestData.headers["SERVICE"] = "km.content.regist.detail-hist";

          this.mstrCntntVer = parseInt(this.mstrCntntVer.substr(0,2)).toString() + "." + parseInt(this.mstrCntntVer.substr(2,4)).toString();
				  this.mstrCntntSeq = this.mstrCntntKey.substring(this.mstrCntntKey.lastIndexOf("_")+1, this.mstrCntntKey.length);

          if(this.mstrCntntVer == ""){
            this.mstrCntntVer = 0;
          }

          if(this.mstrCntntSeq == ""){
            this.mstrCntntSeq = 0;
          }

          requestData.sendData["VER"] = this.mstrCntntVer;
          requestData.sendData["SEQ"] = this.mstrCntntSeq;

        }
        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){

          this.registParams = response.DATA[0];
          this.registParams.CONTN = this.restoreXSS_CKeditor(response.DATA[0].CONTN);
          if(this.registParams.DIV_ID_1LEVEL != ""){
            let DIV_ID_2LEVEL = await this.mixin_getDivCodeData(this.registParams.DIV_ID_1LEVEL);
            this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_2LEVEL, this.registParams.DIV_ID_1LEVEL,"선택");
            this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","선택");
          }

          if(this.registParams.DIV_ID_2LEVEL != ""){
            let DIV_ID_3LEVEL = await this.mixin_getDivCodeData(this.registParams.DIV_ID_2LEVEL);
            this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_3LEVEL, this.registParams.DIV_ID_2LEVEL,"선택");
          }

          this.grid02Data = response.DATA_CONNECT;
        }

      }
    },

    showTempSave(proc_case){

      this.mstrCase = proc_case;

      if(this.registParams.DIV_ID_1LEVEL == ""){
        this.common_alert("컨텐츠분류를 선택해주세요.", "error");
        return;
      }

      if(this.registParams.TITL == ""){
        this.common_alert("제목을 입력해주세요.", "error");
        return;
      }

      if(this.registParams.CONTN == ""){
        this.common_alert("컨텐츠 내용을 입력해주세요.", "error");
        return;
      }

      // if(this.registParams.USER_ATTR_A == ""){
      //   this.common_alert("작성자소속을 선택해주세요.", "error");
      //   return;
      // }
      this.showDialog('M510201P08');
    },
    
    async changeDivId(level){
      if(level == '1'){
        if(this.registParams.DIV_ID_1LEVEL != ""){
          let DIV_ID_2LEVEL = await this.mixin_getDivCodeData(this.registParams.DIV_ID_1LEVEL);
          this.DIV_ID_2LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_2LEVEL, this.registParams.DIV_ID_1LEVEL,"선택");
          this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get("","","선택");
        }

        this.registParams.DIV_ID_2LEVEL = "";
        this.registParams.DIV_ID_3LEVEL = "";
      }else if(level == '2'){
        if(this.registParams.DIV_ID_2LEVEL != ""){
          let DIV_ID_3LEVEL = await this.mixin_getDivCodeData(this.registParams.DIV_ID_2LEVEL);
          this.DIV_ID_3LEVEL_ITEMS = this.mixin_common_code_get(DIV_ID_3LEVEL, this.registParams.DIV_ID_2LEVEL,"선택");
        }

        this.registParams.DIV_ID_3LEVEL = "";
      }
    },
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    getCnnc(data){
      this.grid02Data = data;
      this.grid02Selected = {};
      this.hideDialog('M510201P06');
    },
    getTmplt(data){
      if(this.registParams.CONTN != ""){
        this.common_confirm("템플릿을 적용하면 웹에디터의 작성된 모든내용이 사라집니다. 템플릿을 적용 하시겠습니까?", this.setTmplt, data,null,'','chk')
      }else{
        this.setTmplt(data);
      }
      this.hideDialog('M510201P07');
    },
    setTmplt(data){
      this.registParams.CONTN = data.contn;
    },
    setTmplt2(data){
      this.registParams.CONTN = data.CONTN;
    },
    async processRtn(item){
      this.hideDialog('M510201P08');
      this.mstrChgHistContn = item.CHG_HIST_CONTN;
      this.mstrKeyVerSeq = item.KEY_VER_SEQ;
      this.mstrCntntSeq = item.CNTNT_SEQ;
      this.mstrCntntVer = item.VER;
      this.mstrSaveHistYN = item.SAVE_HIST_YN;
      this.registParams.FST_STRT_DT = (item.FST_STRT_DT).replaceAll("-", "");
      let requestData = {
          headers: {},
          sendData:{},
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/regist/process";
      requestData.headers["SERVICE"] = "km.content.regist";
      requestData.headers["METHOD"] = "process";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      //requestData.sendData = this.registParams;
      // sendData 세팅
      requestData.sendData['KEY_VER_SEQ'] = this.mstrKeyVerSeq;
      requestData.sendData['VER'] = this.mstrCntntVer;
      requestData.sendData['SEQ'] = this.mstrCntntSeq;
      requestData.sendData['CNTNT_ID'] = this.mstrCntntID;

      if(this.registParams.DIV_ID_3LEVEL != ""){
        requestData.sendData['DIV_ID'] = this.registParams.DIV_ID_3LEVEL;
      }else if(this.registParams.DIV_ID_2LEVEL != ""){
        requestData.sendData['DIV_ID'] = this.registParams.DIV_ID_2LEVEL;
      }else if(this.registParams.DIV_ID_1LEVEL != ""){
        requestData.sendData['DIV_ID'] = this.registParams.DIV_ID_1LEVEL;
      }

      let dept_cd = "x";

      if(this.registParams.USER_ATTR_D != ""){
        dept_cd = this.registParams.USER_ATTR_D;
      }else if(this.registParams.USER_ATTR_C != ""){
        dept_cd = this.registParams.USER_ATTR_C;
      }else if(this.registParams.USER_ATTR_B != ""){
        dept_cd = this.registParams.USER_ATTR_B;
      }else if(this.registParams.USER_ATTR_A != ""){
        dept_cd = this.registParams.USER_ATTR_A;
      }

      if(dept_cd == ""){
        dept_cd = "x";
      }

      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;
      requestData.sendData['CHG_HIST_CONTN'] = this.mstrChgHistContn;
      requestData.sendData['SAVE_HIST_YN'] = this.mstrSaveHistYN;
      requestData.sendData['PROC_CASE'] = this.mstrCase;
      requestData.sendData['STATE'] = this.params.STATE;
      requestData.sendData['TITL'] = this.registParams.TITL;
      requestData.sendData['SRCH_KWD'] = this.registParams.SRCH_KWD;
      requestData.sendData['TMPLAT_ID'] = this.registParams.TMPLAT_ID;
      requestData.sendData['CONTN'] = this.restoreXSS_CKeditorDec(this.registParams.CONTN);
      requestData.sendData['FILE_GROUP_KEY'] = this.registParams.FILE_GROUP_KEY;
      requestData.sendData['REGR_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData['REGR_DEPT_CD'] = "x";
      requestData.sendData['AMDR_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      requestData.sendData['AMDR_DEPT_CD'] = "x";

      if(this.mstrCase == "TEMP" && (this.mstrSaveStatCD == "" || this.mstrSaveStatCD == "01")){
        requestData.sendData['SAVE_STAT_CD'] = "01";
        requestData.sendData['IDX_STAT_CD'] = "00";
      }else if(this.mstrCase == "TEMP" && (this.mstrSaveStatCD != "" && this.mstrSaveStatCD != "01")){
        requestData.sendData['SAVE_STAT_CD'] = "01";
        requestData.sendData['IDX_STAT_CD'] = "10";
      }else if(this.mstrCase == "APPROVAL"){
        requestData.sendData['SAVE_STAT_CD'] = "02";
        requestData.sendData['IDX_STAT_CD'] = "01";
        requestData.sendData['APVP_DEPT_CD'] = dept_cd;
        requestData.sendData['APVP_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      }

      requestData.sendData['PROC_ID'] = this.$store.getters['userStore/GE_USER_ID'];
      //this.registParams.REGR_NM = this.$store.getters['userStore/GE_USER_NAME'];

      if(this.grid02Data.length > 0){
        requestData.sendData['DATA_CONNECT'] = this.grid02Data;
        requestData.headers['DATA_OBJECT'] = "DATA_CONNECT";
      }

      // 결과 리턴
      let response = await this.common_postCall(requestData, false);

      if(!response.HEADER.ERROR_FLAG){
        this.common_alert("정상처리되었습니다.","done");
        this.$emit('submit');
      }else{
        this.common_alert(response.HEADER.ERROR_MSG, "error");
      }
    },
    async uploadFile(item){
      this.registParams.FILE_GROUP_KEY = item[0].fileGroupKey;
      this.grid01Data = await this.mixin_getFileList(this.registParams.FILE_GROUP_KEY);
    },

    async deleteRtnFile(){
      if(this.grid01Selected.FILE_GROUP_KEY != null && this.grid01Selected.FILE_GROUP_KEY != ""){
        let rtnBln = await this.deleteFile(this.grid01Selected.FILE_GROUP_KEY, this.grid01Selected.FILE_KEY, this.ASP_NEWCUST_KEY);
        if(rtnBln){
          this.grid01Data.map((e,index)=>{
            if(e === this.grid01Selected){
              this.grid01Data.splice(index,1);
            }
          })
          this.grid01Selected = {};
        }
      }else{
        this.common_alert("삭제할 파일을 선택해주세요.", "error");
      }
    },

    setGird01Selected(item){
      this.grid01Selected = item;
    },
    setGird02Selected(item){
      this.grid02Selected = item;
    },
    isActiveRow(item){
      return item.FILE_KEY == this.grid01Selected.FILE_KEY? 'active':'';
    },
    isActiveRow2(item){
      return item.CNTNT_NO == this.grid02Selected.CNTNT_NO? 'active':'';
    },

    deleteRtnCnnc(){
      if(this.grid02Selected.CNTNT_NO != null && this.grid02Selected.CNTNT_NO != ""){
        this.grid02Data.map((e, index) =>{
          if(e === this.grid02Selected){
            this.grid02Data.splice(index,1);
          }
        });
        this.grid02Selected = {};
      }else{
        this.common_alert("삭제할 컨텐츠를 선택해주세요.", "error");
      }
    },
    async selectRtnUserAttr(){

      let requestData = {
        headers: {},
        sendData:{}
      };
      // header 세팅
      requestData.headers["URL"] = "api/km/content/regist/user-attr/inqire";
      requestData.headers["SERVICE"] = "km.content.regist.user-attr";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY;
      requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];

      // 결과 리턴
      let response = await this.common_postCall(requestData, false);

      if(!response.HEADER.ERROR_FLAG){
        let data = response.DATA;
        if(data.length > 0){
          this.registParams.USER_ATTR_A = data[0].USER_ATTR_A;
          this.registParams.USER_ATTR_B = data[0].USER_ATTR_B;
          this.registParams.USER_ATTR_C = data[0].USER_ATTR_C;
          this.registParams.USER_ATTR_D = data[0].USER_ATTR_D;

        }
      }

    },
  },

  computed: {

  },
};
</script>

<style>

</style>
